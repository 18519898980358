// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galerija-js": () => import("./../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-novosti-js": () => import("./../src/pages/novosti.js" /* webpackChunkName: "component---src-pages-novosti-js" */),
  "component---src-pages-o-nama-js": () => import("./../src/pages/o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */),
  "component---src-pages-sertifikati-js": () => import("./../src/pages/sertifikati.js" /* webpackChunkName: "component---src-pages-sertifikati-js" */),
  "component---src-pages-tim-js": () => import("./../src/pages/tim.js" /* webpackChunkName: "component---src-pages-tim-js" */)
}

